<script>
export default {
  props: {
    props: Object
  }
}
</script>

<template>
  <div class="section-with-bottom-image py-5">
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <h2 class="font-weight-bold">{{props.title}}</h2>
        </div>
        <div class="col-12">
          <b>PetDr. Plan</b> {{props.description}}
        </div>
        <div class="col-12 my-5">
          <img v-bind:src="props.items.imageURL" alt="Insurance carrier picture"> 
        </div>
      </div>
    </div>
  </div>
</template>